import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate, useLocation } from "react-router-dom";
import { Id } from "../../convex/_generated/dataModel";
import { useQuery, useMutation } from "convex/react";
import { api } from "../../convex/_generated/api";
import { TownContext } from "../../contexts/TownContext";
import Game from "../Town/Game";
import { SelectedCharacterProps } from "../../types";
import { getTown } from "../../apis/request";
import useRequest from "../../apis/useRequest";
import { PlatformContext } from "../../contexts/PlatformContext";
import { useAuth0 } from "@auth0/auth0-react";
import { Helmet } from "react-helmet-async";

import * as amplitude from "@amplitude/analytics-browser";
// Custom hook to handle town data fetching
function useTownData(
  town_id: string,
  username: string,
  isAuthenticated: boolean,
  initialTownName?: string,
  initialCharactersMetadata?: SelectedCharacterProps[]
) {
  const makeRequest = useRequest();
  const [townData, setTownData] = useState({
    isSpectator: !isAuthenticated,
    creator: "",
    charactersMetadata:
      initialCharactersMetadata || ([] as SelectedCharacterProps[]),
    townName: initialTownName || "",
    isLoading: !(initialCharactersMetadata && initialTownName), // Check both conditions
  });

  useEffect(() => {
    if (initialCharactersMetadata && initialTownName) {
      return;
    }

    let isSubscribed = true;

    const fetchTownData = async () => {
      try {
        const requestUsername = isAuthenticated ? username : "anonymous";
        const response = await makeRequest<any, any>(
          getTown(requestUsername, town_id)
        );

        // if (isSubscribed) {
        setTownData({
          isSpectator: !isAuthenticated || response.username !== username,
          creator: response.username,
          charactersMetadata: response.characters,
          townName: response.town_name,
          isLoading: false,
        });

        // }
      } catch (error) {
        console.error("Failed to fetch town data:", error);
        // if (isSubscribed) {
        setTownData((prev) => ({ ...prev, isLoading: false }));
        // }
      }
    };

    fetchTownData();

    return () => {
      isSubscribed = false;
    };
  }, [
    town_id,
    username,
    isAuthenticated,
    makeRequest,
    initialCharactersMetadata,
    initialTownName,
  ]);

  return townData;
}

function Walltown({ fixed_spot_id }: { fixed_spot_id?: string }) {
  const { town_id } = useParams<{ town_id: string }>();
  const spot_id = fixed_spot_id || town_id;
  const location = useLocation();
  const { isAuthenticated } = useAuth0();
  const context = useContext(PlatformContext);
  const freeze = useMutation(api.testing.stop);

  if (!spot_id) throw new Error("spot_id is undefined");
  if (!context) throw new Error("PlatformContext is undefined");

  const { username } = context;
  const initialCharactersMetadata = location.state?.charactersMetadata;
  const initialTownName = location.state?.townName;
  const { isSpectator, creator, charactersMetadata, townName, isLoading } =
    useTownData(
      spot_id,
      username,
      isAuthenticated,
      initialTownName,
      initialCharactersMetadata
    );

  // Add this check after the town_id validation

  const cryptoSpot: boolean = spot_id === "md79y1ntrnjcf4pvfg5rsykj2579r9t9";

  // Handle amplitude tracking
  useEffect(() => {
    if (isLoading) return;

    const eventData = {
      town_id: spot_id,
      town_name: townName,
      username,
    };

    amplitude.track(isSpectator ? "Start View Spot" : "Start Spot", eventData);
    let hasStarted = false;
    setTimeout(() => {
      hasStarted = true;
    }, 1000);
    return () => {
      if (hasStarted && !cryptoSpot) {
        amplitude.track(isSpectator ? "End View Spot" : "End Spot", eventData);
        if (!isSpectator) {
          freeze({ worldId: spot_id as Id<"worlds"> });
        }
      }
    };
  }, [town_id, townName, username, isSpectator, freeze, isLoading]);

  // Handle world status
  const { worldStatus } =
    useQuery(api.world.getWorldStatus, {
      worldId: spot_id as Id<"worlds">,
    }) ?? {};

  // Add these new state and title handling
  const titleMap = {
    "topdawg.fun": "TopDawg",
    localhost: "TopDawg",
  };

  const getPageTitle = () => {
    const hostname = window.location.hostname;
    const hostnameTitle = titleMap[hostname as keyof typeof titleMap];

    if (cryptoSpot) {
      return "TopDawg";
    } else if (hostnameTitle) {
      return hostnameTitle;
    }
    return townName ? `${townName} - 4Wall AI` : "4Wall AI";
  };

  const pageTitle = getPageTitle();

  if (isLoading || !worldStatus) {
    return (
      <>
        <Helmet>
          <title>Loading... - 4Wall AI</title>
          <link rel="icon" type="image/jpeg" href="/favicon.jpg" />
        </Helmet>
        <div className="flex flex-col justify-center items-center h-screen bg-black">
          <img
            src="/spot_loading.gif"
            alt="Loading"
            className="w-[20rem] rounded-full"
          />
          <p className="text-xl font-main font-bold text-white">Loading...</p>
        </div>
      </>
    );
  }

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        {cryptoSpot && (
          <meta
            name="description"
            content="The Social Metaverse for AI Agents"
          />
        )}
        {cryptoSpot && (
          <link rel="icon" type="image/jpeg" href="/topdawg.png" />
        )}
      </Helmet>
      <TownContext.Provider
        value={{
          townName,
          setTownName: () => {},
          town_id: spot_id as string,
          charactersMetadata,
          setCharactersMetadata: () => {},
          isSpectator,
          creator,
          cryptoSpot,
        }}
      >
        <Game worldStatus={worldStatus} townName={townName} />
      </TownContext.Provider>
    </>
  );
}

export default Walltown;
